.imagea,
.imageb,
.imagec {
    width: 100%;
}

@media screen and (max-width: 640px) {
    .imagea {
        transform: scale(2.5) translate(-13px, 11px);
    }
    .imageb {
        transform: scale(1) translate(0px, 60px);
    }
    .imagec {
        transform: scale(1.6) translate(15px, -10px);
    }
}

@media (min-width: 640px) {
    .imagea,
    .imageb,
    .imagec {
        height: 100%;
    }

    .imagea {
        transform: scale(3);
    }

    .imageb {
        transform: scale(1.7) translate(20px, 40px);
    }

    .imagec {
        transform: scale(2) translate(35px, -20px);
    }
}
@media screen and (min-width: 868px) {
    .imageb {
        transform: scale(1.7) translate(30px, 40px);
    }
}
@media screen and (min-width: 1024px) {
    .imagea {
        transform: scale(3);
    }

    .imageb {
        transform: scale(1.7) translate(60px, 40px);
    }

    .imagec {
        transform: scale(2) translate(35px, -20px);
    }
}
@media screen and (min-width: 1280px) {
    .imagea,
    .imageb,
    .imagec {
        height: auto;
    }
    .imagea {
        transform: scale(3) translate(-3px, 9px);
    }

    .imageb {
        transform: scale(1) translate(0px, 60px);
    }

    .imagec {
        transform: scale(1.7) translate(19px, 0px);
    }
}

@media screen and (min-width: 1536px) {
    .imagea {
        transform: scale(2.3) translate(-18px, -6px);
    }

    .imageb {
        transform: scale(1) translate(0px, 60px);
    }

    .imagec {
        transform: scale(1.5) translate(0px, 0px);
    }
}
