.statusWrap {
    position: relative;
    margin-top: 20px;
}

.card {
    width: 100%;
    height: min-content;
    display: flex;
    flex-direction: column;
    background-color: var(--c-contrast-1);
    transition: background-color 0.2s ease;
    padding: 12px;
    border-radius: 8px;

    .mainRow {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .basis {
        display: flex;
        flex-direction: column;
        column-gap: 20px;
        width: 100%;
    }

    .verifiedTag {
        position: absolute;
        left: -24px;
        top: 4px;
    }

    .more {
        max-height: 0px;
        transition:
            max-height 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275),
            opacity 0.1s ease-in-out;
        width: 100%;
        height: 100%;
        opacity: 0;

        &.expanded {
            max-height: 1000px;
            opacity: 1;
        }
    }

    .expandBtnWrap {
        display: flex;
        flex-direction: row;
        justify-content: center;
        background-color: var(--c-grey-8);
        transition: background-color 0.2s ease;
        border-radius: 8px;

        &:hover {
            background-color: var(--c-grey-12);
        }

        &.hidden {
            display: none;
        }

        .expandBtn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 28px;
            cursor: pointer;
        }
    }

    &:hover {
        background-color: var(--c-grey-8);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    }

    &[data-theme='dark'] {
        .expandBtnWrap:hover {
            background-color: rgba(255, 255, 255, 0.12);
        }
    }
}

.row {
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    width: 100%;
    column-gap: 20px;

    .mainRow {
        display: flex;
        flex-direction: row;
        width: 100%;

        &.withExpansion {
            // width: calc(100% - 40px);
        }
    }

    .verifiedTag {
        position: absolute;
        left: -15px;
        top: 32px;
    }

    .basis {
        display: flex;
        flex-direction: column;
        column-gap: 0px;
        width: 100%;

        .more {
            max-height: 0px;
            opacity: 0;
            transition:
                max-height 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275),
                opacity 0.1s ease-in-out;
            width: 100%;

            &.expanded {
                max-height: 500px;
                overflow-y: auto;
                opacity: 1;

                @media (min-width: 1300px) {
                    max-height: auto;
                }
            }
        }
    }

    .expandBtnAssets {
        top: 20px;
        min-width: 40px;
        max-width: 40px;
        padding: 8px;
        margin: 0 -12px;
    }

    .expandBtn {
        top: 15px;
        min-width: 40px;
        max-width: 40px;
        margin: 10px -12px;
    }
}

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.table {
    display: table;
    width: 100%;
    margin-top: 20px;
    border-spacing: 0 4px;
}

.tableRow {
    display: table-row;
}

.dataStatus {
    margin-top: 20px;
}

.tableHeader {
    display: grid;
    align-items: center;
    grid-template-columns: 110px 1fr;
    gap: 8px;
}
