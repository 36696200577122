.hero {
    display: flex;
    flex-direction: column;
    row-gap: clamp(24px, 4vw, 48px);
    width: 100%;
    margin-bottom: 48px;

    .heroInfoWrap {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: flex-end;
        gap: 24px;
        width: 100%;

        .titleWrap {
            .marketcap {
                font-weight: bold;
            }
        }

        .stats {
            display: grid;
            gap: 24px;
            grid-template-columns: repeat(2, 1fr);

            .metric {
                width: 100%;
            }
        }

        @media (max-width: 1120px) {
            grid-template-columns: 1fr;
            align-items: flex-start;

            .stats {
                grid-template-columns: repeat(4, 1fr);
                width: 100%;
            }
        }

        @media (max-width: 1049px) {
            .stats {
                grid-template-columns: repeat(2, 1fr);
            }
        }

        @media (max-width: 519px) {
            .stats {
                grid-template-columns: 1fr;
                row-gap: 16px;
            }
        }
    }

    .features {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        background-color: var(--c-fg);
        border-radius: 24px;
        overflow: hidden;

        .featureWrap {
            min-width: 300px;
            padding: 32px 0;

            &:hover {
                background-color: var(--c-contrast-4);

                .feature {
                    border-right: 1px solid transparent;
                }
            }

            &:last-child {
                .feature {
                    border-right: 1px solid transparent;
                }
            }

            .feature {
                display: flex;
                flex-direction: column;
                padding: 0 32px;
                text-align: center;
                border-right: 1px solid var(--c-contrast-5a);

                .icon {
                    --size: 40px;
                    margin: 0 auto;
                    background-color: var(--c-bg);

                    &.vsp {
                        width: 72px;
                        height: 32px;
                        background-color: transparent;
                        margin-bottom: 8px;
                    }

                    &.calculator {
                        --size: 32px;
                        margin-bottom: 8px;
                    }
                }

                .label {
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 1;
                    color: var(--c-bg);
                    margin-bottom: 8px;
                    white-space: nowrap;
                }

                .description {
                    font-size: 14px;
                    font-weight: normal;
                    line-height: 1.5;
                    color: var(--c-contrast-1);
                    opacity: 0.8;
                    letter-spacing: 0.5px;
                }
            }
        }

        @media (max-width: 1399px) {
            .featureWrap {
                min-width: 100%;

                .feature {
                    padding: 0 20px;

                    .description {
                        display: none;
                    }
                }
            }
        }

        @media (max-width: 1049px) {
            grid-template-columns: repeat(2, 1fr);

            .featureWrap {
                padding: 0;

                .feature {
                    padding: 32px 0;
                    border-top: 1px solid var(--c-contrast-5a);

                    &:hover {
                        border-top: 1px solid transparent;
                    }
                }
            }
        }

        @media (max-width: 599px) {
            grid-template-columns: 1fr;
        }
    }
}

.tooltip {
    display: flex;
    width: 100%;
}

@keyframes fadeInOut {
    0%,
    5% {
        opacity: 0;
        transform: translateY(10px);
    }
    5%,
    85% {
        opacity: 1;
        transform: translateY(0);
    }
    85%,
    90% {
        opacity: 1;
        transform: translateY(0);
    }
    90%,
    100% {
        opacity: 0;
        transform: translateY(-10px);
    }
}

.animationFadeInOut {
    animation: fadeInOut 2.5s ease 1;
}
