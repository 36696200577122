.backgroundGradient1 {
    border-radius: 1146px;
    opacity: 0.4;
    width: 1146px;
    height: 360.52px;
    left: 671px;
    top: -170px;
    background: radial-gradient(50% 50% at 50% 50%, #02a4ff 0%, rgba(2, 164, 255, 0) 100%);
    filter: blur(100px);
    position: absolute;
    z-index: 1;
    pointer-events: none;
}

.backgroundGradient2 {
    border-radius: 1709px;
    opacity: 0.2;
    background: radial-gradient(50% 50% at 50% 50%, #ae02ff 0%, rgba(189, 2, 255, 0) 100%);
    width: 1709px;
    height: 430.746px;
    filter: blur(100px);
    left: 87px;
    top: -80px;
    position: absolute;
    z-index: 1;
    pointer-events: none;
}

.backgroundgradient3 {
    border-radius: 1489px;
    opacity: 0.4;
    background: radial-gradient(50% 50% at 50% 50%, #02a4ff 0%, rgba(2, 164, 255, 0) 100%);
    width: 1489px;
    height: 439.785px;
    filter: blur(100px);
    left: -410px;
    position: absolute;
    top: -224px;
    z-index: 1;
    pointer-events: none;
}

.home {
    text-align: center;
    padding: var(--p-page-top) var(--p-page-sides);
    .banners {
        display: flex;
        flex-direction: column;
        gap: 60px;
    }

    .adWrap,
    .vspWrap,
    .tableWrap,
    .calculatorWrap {
        margin-bottom: 60px;
    }

    .adWrap {
        overflow-x: auto;
    }

    .calculatorWrap,
    .tableWrap {
        .topWrap {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 12px;
            justify-content: space-between;

            .headerWrap {
                display: flex;
                flex-direction: column;
            }
        }
    }

    .tableWrap {
        @media (max-width: 767px) {
            margin-left: -30px;
            margin-right: -30px;

            .topWrap {
                padding: 0 30px;
            }
        }

        // For cards:
        @media (max-width: 640px) {
            margin-left: -12px;
            margin-right: -12px;

            .topWrap {
                padding: 0 12px;
            }
        }

        .filtersWrap {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 16px 20px;
            align-self: flex-end;
            // On top of the table
            z-index: 9;

            .sortByFilter {
                width: 170px;
            }

            .categoryFilter {
                width: 120px;
            }
        }
    }

    .topWrap {
        margin-bottom: 32px;
    }

    .linkWrap {
        align-self: flex-end;
    }
}
