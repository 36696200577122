.vspSection {
    .featureWrap {
        margin: auto;
        max-width: 400px;
        margin-bottom: 160px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .iconsWrap {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        overflow: hidden;
        width: 100vw;
        margin-left: -50vw;
        left: 50%;
        padding-top: 10px;
        margin-bottom: 40px;
    }

    .buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px 20px;
        top: -127px;
        z-index: 3;

        .btnWrap {
            width: 170px;
        }
    }

    .features {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 40px;

        .featureWrap {
            width: 340px;
        }
    }

    .trustedByWrap {
        text-align: center;
        margin-bottom: 160px;

        .trustedBy {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            gap: 20px;

            .logo {
                height: 80px;
                width: 100px;
            }

            .img {
                object-fit: contain;
            }
        }
    }
}
