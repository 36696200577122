.feature {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 550px;
    text-align: center;

    .icon {
        margin: 0 auto 12px auto;
        width: 72px;
        height: 24px;

        img {
            object-fit: cover;
        }
    }

    .label {
        margin-bottom: 8px;
    }
}
